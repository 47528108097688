export const resources = {
	en: {
		translation: {
			'login.title': 'Login into Your Account',
			'login.description': 'Enter your ID Number to continue',
			'login.form.input': 'Enter your ID Number ',
			'login.continue': 'Continue',
			'login.website': 'Website: ',
			'signup.intro': 'New to Sunculture ? Fill in this ',
			'signup.form': 'form',
			'signup.intro2': 'to get you started',
			'signup.title': 'Create your Account',
			'signup.form.firstName': 'First(Given) Name',
			'signup.form.lastName': 'Last(Surname) Name',
			'signup.form.phoneNumber': 'Phone Number',
			'signup.form.idNumber': 'Identification Number',
			'signup.form.referrer.name': 'Referral Full Name',
			'signup.form.referrer.phoneNumber': 'Referral Phone Number',
			'signup.submit': 'Submit',
			'signup.confirm':
				'Confirm you are a new customer ? Are you sure you would like to sign up {{firstName}}?',
			'signup.resource.failed':
				'The customer is already registered. Thank you for your time.',
			'signup.sucess':
				'You have been successfully registered on our systems. A SunCulture associate should reach out within 24 hours to speak to you on next steps in setting up the account',
			'call.toll': 'Call toll free line: ',
			'otp.verify': "Verify it's you",
			'otp.description': 'Please enter verification code sent via sms to ',
			'otp.form.input': 'Enter your OTP',
			'otp.login': 'Login',
			'otp.success': 'The OTP provided has been verified successfully',
			'menu.dashboard': 'Dashboard',
			'menu.ticket': 'Get in Touch',
			'menu.account': 'Account Info',
			'menu.device': 'My Device',
			'menu.referral': 'My Referrals',
			'menu.faq': 'Faqs',
			'menu.logout': 'Logout',
			'header.dashboard': 'My Dashboard',
			'header.device': 'My Devices',
			'header.ticket': 'My Concerns',
			'header.account': 'Account Info',
			'header.referral': 'My Referrals',
			'header.referral.detail': 'Referral Details',
			'header.ticket.comment': 'Ticket Comments',
			'good.morning': 'Good Morning',
			'good.evening': 'Good Evening',
			'good.afternoon': 'Good Afternoon',
			'dashboard.account': 'My Account',
			'dashboard.product': 'Product',
			'dashboard.product.amount': 'Product Amount',
			'dashboard.status': 'Status',
			'payment.info': 'PAYMENT INFORMATION',
			'installment.info': 'INSTALLMENT INFORMATION',
			'total.amount.paid': 'TOTAL AMOUNT PAID',
			'total.amount.due': 'TOTAL AMOUNT DUE',
			'total.loan.balance': 'TOTAL LOAN BALANCE',
			'next.install.amount': 'NEXT INSTALLMENT AMOUNT',
			'next.install.date': 'NEXT INSTALLMENT DATE',
			'payment.history': 'PAYMENT HISTORY',
			'no.payment.history': 'Oops! Sorry No Payment Records found.',
			'fetch.payment': 'Fetching Payments. Please wait...',
			'fetch.forecast': 'Fetching Weather Forecast. Please wait...',
			'call.free': 'Call us on: ',
			'concern.raised': 'Concerns Raised',
			'referral.list': 'Referral List',
			'title.verify.device': 'Verify Your Device',
			'desc.verify.device': 'Enter your Device ID to Verify',
			'device.form.input': 'Enter Your device ID',
			'btn.verify.device': 'VERIFY DEVICE',
			'raise.concern': 'Raise Concern',
			'call.toll.free.line': `Call toll free line: `,
			'label.note': 'Note: ',
			'first.note':
				'You are limited to three wrong attempts,if all fail you will need to try again after 24 hours.',
			'second.note':
				'2. The device ID value can also be found momentarily you turn on your battery that was previously off.',
			'enter.device': 'Please enter your Device ID',
			'device.length': 'Request failed. Device ID should have 15 characters',
			'device.start':
				'Request failed. Invalid Device ID entered. Please try again',
			'label.close': 'Close',
			'change.language': 'Change to: ',
			'label.reason': 'Reason',
			'label.description': 'Description',
			'description.input': 'Enter a description about an issue',
			'submit.button': 'Submit',
			'refer.someone': 'Refer Someone',
			'label.referrals': 'Referrals',
			'label.first.name': 'Enter Customer First name',
			'label.last.name': 'Enter Customer Last name',
			'label.phone': 'Enter Phone Number',
			'label.location': 'Location',
			'label.watersource': 'Watersource',
			'label.purchasedate': 'Purchase Date',
			'label.followupdate': 'Follow up Date',
			'referral.number': 'Referral Number',
			'label.product.interest': 'Product of Interest',
			'label.select.watersource': 'Select Watersource',
			'label.select.country': 'Select Country',
			'label.select.product': 'Select Product of Interest',
			'list.details': 'List Details',
			'load.ticket': 'Fetching tickets. Please wait...',
			'empty.ticket': 'Oops! It seems there are no raised tickets at the moment.',
			'load.referral': 'Fetching referrals. Please wait...',
			'empty.referral': 'Oops! Sorry No List of Referrals found.',
			'enter.valid.id': 'Please enter a Valid Identification Number.',
			'login.no.account': 'Request failed. No Account info found.',
			'please.input.otp': 'Please Input OTP on your phone to continue!',
			'valid.input.otp': 'Please enter the valid OTP code!',
			'login.success': 'Sms code has been sent to your device.',
			'invalid.input.otp': 'Oops! Invalid OTP Code entered. Please try again',
			'session.expired': 'Your session has expired. We are logging you out.',
			'something.wrong':
				'Request failed. Something Went Wrong. Please try again.',
			'confirm.raise.ticket': 'Confirm you want to raise a ticket?',
			'confirm.ticket':
				'Your ticket has been submitted successfully. Our team will reach out within 24 hours to follow up on this. Thank you for being a SunCulture customer',
			'label.loading': 'Loading...',
			'confirm.logout': 'Are you sure you want to logout?',
			'find.id':
				'Kindly find the device ID at the back of your battery pack which begins with 86092204',
			'where.can.find.id': 'Where can I find my',
			'device.id.question': 'Device ID?',
			'label.ok': 'Ok',
			'forecast.title': '6-DAY FORECAST',
			'btn.correct': 'Correct',
			'btn.not.correct': 'Not correct',
			'geolocation.not.supported':
				'Geolocation is not supported by your browser',
			'label.locating': 'We are Locating you. Please wait...',
			'unable.retrieve':
				'Oops! Unable to retrieve your location. Please allow browser to access your location',
			'current.location': 'Your current location: ',
			'label.river': 'River',
			'label.dam': 'Dam',
			'label.shallow.water': 'Shallow water',
			'label.lake': 'Lake',
			'label.rain': 'Rain',
			'label.rain.water.harvest': 'Rain Water harvest',
			'pay.now': 'Pay Now',
			'label.cancel': 'Cancel',
			'mpesa.form.msisdn': 'Enter your phone number(Start with 254/256)',
			'mpesa.form.amount': 'Enter Amount to pay',
			'mpesa.form.description': 'Description',
			'mpesa.success.launch':
				'Your Request to pay has been received successful. Wait and enter your PIN on your Device',
			'mpesa.dialog.desc':
				'Enter your phone number, amount and description to complete payment process.',
			'label.download': 'Download',
			'customer.enquiry': 'Customer Enquiry',
			'payment.issue': 'Payment Issue',
			'technical.issue': 'Technical Issue',
			'device.upgrade': 'Device Upgrade',
			'device-nb': 'NB',
			'note.description':
				'Please find more Information on the above select reasons when raising a ticket',
			'reason.header': 'Reason',
			'more.header': 'More Info',
			'enquiry.info':
				'Questions/ Are you seeking clarification regarding our services or products',
			'complaint.info': 'Unsatisfactory Issue',
			'technical.info': 'Having issues with your system? Let us know',
			'payment.more-info':
				'Raise compliant around your payments e.g. a particular payment is not going through',
			'device.info':
				'Interested in purchasing an add-on to your existing product or upgrade?',
			'characters-msg': 'Must be more than one character',
			'input-number-msg': 'Please input your phone number',
			'phone-input-digits': 'Please enter a valid phone number',
			'idnumber-input-digits': 'Please enter a valid identification number',
			'go-back-btn': 'Go Back',
			'header.refer.earn': 'Refer Somebody and Earn KSH.1000',
			'train.link.title1': 'Improve your',
			'train.link.title2': 'Yield Today',
			'explore.training': 'Explore our trainings',
			'header.take.training': 'Take training',
			'device.info.today': 'Today',
			'device.info.weekly': 'Weekly',
			'device.info.watthours': 'Watt hours produced',
			'device.info.wattconsumed': 'Watt hours consumed',
			'device.info.wattsgenerating': 'Watts generating',
			'device.info.moneysaved': 'Money saved:',
			'device.info.lastconnected': 'Last Connected:',
			'device.info.performance': 'The performance of device',
			'mydevice.verify.help.title':
				'On the back of the battery that starts with',
			'mydevice.verify.help.id': '86',
			'label.Watt': 'Watt',
			'label.Watthours': 'Watt Hours',
			'message.session.expired':
				'Request failed. Your Session has expired. Please logout and login again',
			'message.unable.verify.device':
				'Request failed. We are not able to verify your device at this time',
			'message.no.device.info': 'Sorry no device information available',
			'login.no.account.button': 'No account?',
			'login.join.suncculture': ' Join SunCulture',
			'weather.for.week': 'Weather for the week',
			'weather.humidity': 'Humidity',
			'login.account.already': 'Already have an account ?',
			'login.here': 'Login here',
			'label.optional': 'Optional',
			'signup.error.msg.firstname': 'Please Enter First Name',
			'signup.error.msg.firstname.invalid': 'First name should have more than one letter',
			'signup.error.msg.lastname': 'Please Enter Last Name',
			'signup.error.msg.lastname.invalid': 'Last name should have more than one letter',
			'signup.error.msg.phonenumber':
				'Please Enter Valid Phone Number start with 254 or 256',
			'signup.error.msg.refferal.phonenumber':
				'Please Enter Valid Refferal Phone Number start with 0 or 254 or 256',
			'signup.error.msg.location': 'Please Enter a Location',
			'signup.error.msg.referral.name': 'Please Enter Referral Name',
			'yield.improve.title': 'Improve your yield today',
			'yield.improve.subtitle': 'Explore our trainings',
			'btn.label.explore': 'Explore',
			'mydevice.not.verified': 'Your device is not verified',
			'btn.title.see.more': 'See more',
			'label.complaint': 'Complaints',
			'mydevice.verify.help.note2':
				'please Enter a 15 digits Device ID to verify your device',
			'ticket.tab.data.all': 'All',
			'ticket.tab.data.pending': 'Pending',
			'ticket.tab.data.done': 'Done',
			'message.unable.fetch.device.information':
				'Request failed. We are not able to fetch your device information at this time',
			'msg.no.device.id.attatched':
				'No device has been assigned to this account. Kindly call us on our toll free line or raise a concern for any challenges you may be facing while verifying your device',
			'account.installement': 'Installment information',
			'account.how.to.pay': 'How to Pay',
			'payment.reminder.needtopay': 'You need to pay',
			'payment.reminder.pastdue': 'Past due',
			'btn.title.pay': 'Pay',
			'productive.top.users': 'Top Users SunCulture',
			'productive.5points.title': 'for Login to Customer Portal',
			'productive.50points.title': 'for a new referral',
			'productive.12points.title': 'for a new referral',
			'label.productivity.assesment': 'My Productivity Assessment',
			'label.past.due': 'Past due',
			'device.no.telemetry.data': 'service not available, try again later',
			'accounts.payments.done': "The account's payments are up to date",
			'label.account': 'Account',
			'btn.label.refer': 'Refer',
			'invite.reffer.frined': 'Invite a friend and earn points',
			'label.banner.point.text': 'points',
			'label.no.payment.left': "The account's payments are up to date",
			'label.no.due.arrears': 'NO DUE ARREARS',
			'label.total.amount': 'Potential amount',
			'refer.card.id': 'Referral ID',
			'refer.card.name': 'Name',
			'refer.card.phone': 'Phone Number',
			'refer.card.location': 'Location',
			'refer.card.product.interested': 'Product Interested',
			'refer.card.earned.money': 'Earned Money',
			'label.no.due.installment': 'NO DUE INSTALLMENT',
			'label.do.refer': 'Refer your Friend',
			'label.installment.note': 'Installment Amount Calculation',
			'label.info.for.installment':
				'The next installment amount is calculated by adding past due payments (if any) and your usual installment payment of KES.',
			'label.info.part.2.for.installment':
				'This value may be less when you pay early.',
			'label.create.refer.note': 'Get more info about SunCulture products',
			'soil.test.title': 'The results of your soil test done',
			'btn.title.see': 'See',
			'not.found.page.title': 'Oops! Sorry Page Not Found.',
			'select.device.title': 'Select your Account',
			'select.device.desc': 'Please select an account to continue',
			'label.retry': 'Retry',
			'title.select.device': 'Select a Device',
			'label.select.device': 'Switch Device',
			'label.confirm.reload':
				'Are you sure you want to leave? All provided data will be lost.',
			'label.select.account': 'Select a Account',
			'title.register.device': 'Register Your Device',
			'title.btn.register.device': 'Register Device',
			'title.btn.view.register.device': 'View Details',
			'title.btn.self.register.device.details':
				'Self registered Device Details',
			'device.register.form.deviceId': 'Device ID',
			'device.register.form.paygContractNumber': 'Contract Number',
			'device.register.form.passportImage': 'Passport Size Photo',
			'device.register.form.frontIdImage': 'ID Photo (Front)',
			'device.register.form.backIdImage': 'ID Photo (Back)',
			'device.register.form.farmImage': 'Farm Photo',
			'device.register.form.pumpImage': 'Pump Photo',
			'device.register.form.waterSourceImage': 'Water Source Photo',
			'device.register.form.deviceIdImage': 'Device ID Photo',
			'device.register.form.signedContractImage': 'Contract Photo',
			'device.register.form.warrantCardImage': 'Warranty Card Photo',
			'label.select.image': 'Upload Image',

			'device.register.form.farmOwnership': 'Farm ownership',
			'device.register.form.farmmanagement': 'Farm management',

			'device.register.form.state': 'Select County',
			'device.register.form.substate': 'Select Sub County',
			'device.register.form.town': 'Town',
			'device.register.form.hasWater': 'Water Availability',
			'device.register.form.hasElectricityOnFarm': 'Electricity on Farm',
			'device.register.form.waterSourceDepth': 'Water Depth',
			'device.register.form.waterSourceDistance': 'Water Distance',
			'device.register.form.whenWasWellDug': 'Well Dug',
			'device.register.form.monthlyPumpingCost': 'Monthly Pump Cost',
			'device.register.form.currentIrrigationMethod': 'Irrigation Method',
			'device.register.form.totalFarmSizeAcres': 'Farm Size (Acres)',
			'device.register.form.waterTankCapacityInLitres':
				'Water Tank Capacity (ltrs)',
			'device.register.form.cropsToBeGrown': 'Crops Grown',
			'device.register.form.didSoilTest': 'Soil Test',
			'device.register.form.property.images': 'Property Images',
			'device.register.form.waterSource.images': 'Water Source Images',
			'device.register.form.farmName': 'Farm name',
			'label.yes': 'Yes',
			'label.no': 'No',
			'label.remove': 'Remove',
			'label.add.images.property': '+ Property Images',
			'label.add.images.watersource': '+ Water Source Images',
			'label.no.device': 'No Device Available',
			'title.self.register.banner': 'Now Self Register your Device ',
			'label.self.register.banner':
				'Have a Self registering device! Just click on Register and register your device in some simple steps',
			'label.back': 'Back',
			'label.next': 'Save',
			'label.err.message.stepper.form':
				'*Make sure you have entered all the required details correctly',
			'label.success.form.submitted':
				'You have successfully submitted all the required details for registering the device',
			'label.step': 'Step',
			'label.purchase.date': 'Purchase Date',
			'label.now': 'Now',
			'label.two.weeks': 'Two Weeks',
			'label.two.months': 'Two Months',
			'label.later': 'Later',
			'label.submitted.suceess.title':
				'You have successfully submitted all the required details for registering the device.',
			'label.submitted.suceess.sub.title':
				'Please wait for the Sunculture Team to approve your device.',
			'label.submitted.suceess.note': 'This may take few hours.',
			'label.registration.suceess.title':
				'Your Device has been successfully registered.',
			'label.registration.suceess.sub.title':
				'You can view the submitted details using the buttons below',
			'label.farm.details.note':
				'Please ensure that you are at your farm while updating your location ',
			'label.btn.add.location': 'Add Location',
			'label.btn.take.pictures': 'Take Picture',
			'title.add.permise.location': 'Add Farm Location',
			'title.save.image': 'Save Image',
			'title.camera': 'CAMERA',
		},
	},
	sw: {
		translation: {
			'login.title': 'Ingia kwenye Akaunti yako',
			'login.description': 'Weka Nambari yako ya Kitambulisho ili kuendelea',
			'login.form.input': 'Weka Nambari yako ya Kitambulisho',
			'login.continue': 'Endelea',
			'login.website': 'Tovuti: ',
			'signup.title': 'Fungua Akaunti yako',
			'signup.form.firstName': 'Jina la kwanza',
			'signup.form.lastName': 'Jina la familia',
			'signup.form.phoneNumber': 'Nambari ya simu',
			'signup.form.idNumber': 'Nambari ya kitambulisho',
			'signup.form.referrer.name': 'Jina Kamili la Rufaa',
			'signup.form.referrer.phoneNumber': 'Nambari ya Simu ya Rufaa',
			'signup.submit': 'Wasilisha',
			'signup.intro': 'Mgeni SunCulture ? Jaza hii ',
			'signup.form': ' fomu ',
			'signup.intro2': ' kujiunga',
			'signup.confirm':
				'Thibitisha wewe ni mteja mpya ? Unauhakika unataka kujisajili {{firstName}}?',
			'signup.resource.failed': 'Uwasilisaji umefeli,Tafadhali jaribu tena',
			'signup.sucess':
				'Umesajiliwa kwa ufanisi kwenye mifumo yetu. Mshirika wa SunCulture anapaswa kuwasiliana nawe ndani ya saa 24 ili kuzungumza nawe kuhusu hatua zinazofuata za kusanidi akaunti',
			'call.toll': 'Piga simu: ',
			'otp.verify': 'Thibitisha kuwa ni wewe',
			'otp.description':
				'Tafadhali weka nambari ya kuthibitisha iliyotumwa kupitia sms kwa ',
			'otp.form.input': 'Weka OTP yako',
			'otp.login': 'Ingia',
			'otp.success': 'OTP iliyowekwa imethibitishwa kwa ufanisi',
			'menu.dashboard': 'Dashibodi',
			'menu.ticket': 'Wasiliana Nasi',
			'menu.account': 'Maelezo ya Akaunti',
			'menu.device': 'Kifaa changu',
			'menu.referral': 'Marejeleo Yangu',
			'menu.faq': 'Faqs',
			'menu.logout': 'Kuondoka',
			'header.dashboard': 'Dashibodi Yangu',
			'header.device': 'Kifaa Changu',
			'header.ticket': 'Hoja Zangu',
			'header.account': 'Maelezo ya Akaunti',
			'header.referral': 'Marejeleo Yangu',
			'header.referral.detail': 'Maelezo ya Rufaa',
			'header.ticket.comment': 'Maoni ya Tikiti',
			'good.morning': 'Habari ya Asubuhi',
			'good.evening': 'Habari za Jioni',
			'good.afternoon': 'Habari Za Mchana',
			'dashboard.account': 'Akaunti Yangu',
			'dashboard.product': 'Bidhaa',
			'dashboard.product.amount': 'Kiasi cha Bidhaa',
			'dashboard.status': 'Hali',
			'payment.info': 'TAARIFA ZA MALIPO',
			'installment.info': 'TAARIFA ZA AWAMU',
			'total.amount.paid': 'JUMLA YA KIASI UMELIPA',
			'total.amount.due': 'JUMLA YA KIASI KINACHODAIWA',
			'total.loan.balance': 'JUMLA YA MALIPO YA MKOPO',
			'next.install.amount': 'AWAMU IFUATAYO',
			'next.install.date': 'TAREHE YA USIMAMIZI UNAOFUATA',
			'payment.history': 'HISTORIA YA MALIPO',
			'no.payment.history':
				'Lo! Samahani Hakuna Rekodi za Malipo zilizopatikana.',
			'fetch.payment': 'Inaleta Malipo. Tafadhali subiri...',
			'fetch.forecast': 'Inaleta Utabiri wa Hali ya Hewa. Tafadhali subiri...',
			'call.free': 'Tupigie simu kwa: ',
			'concern.raised': 'Hoja Umeongeza',
			'referral.list': 'Orodha ya Rufaa',
			'title.verify.device': 'Thibitisha Kifaa Chako',
			'desc.verify.device': 'Weka Kitambulisho cha Kifaa chako ili Uthibitishe',
			'device.form.input': 'Weka Kitambulisho cha Kifaa chako',
			'btn.verify.device': 'THIBITISHA KIFAA',
			'raise.concern': 'Wasilisha Hoja',
			'call.toll.free.line': `Piga simu ya bure: `,
			'label.note': 'Ufafanuzi: ',
			'first.note':
				'Umepewa majaribio matatu tu kuweka nambari ya kifaa, Isipowasilishwa, Tafadhali jari tena baada ya masaa 24 au siku moja',
			'second.note':
				'2. Nambari ya Kifaa chako kinaweza patikana pia punde utakapo washa betri iliyozimwa.',
			'enter.device': 'Weka nambari ya kifaa',
			'device.length':
				'Ombi umefeli. Nambari ya kifaa lazima iwe herufi kumi na tano',
			'device.start':
				'Ombi umefeli. Nambari uliyo weka haijulikani, Tafadhali jaribu tena',
			'label.close': 'Funga',
			'change.language': 'Badilisha iwe: ',
			'label.reason': 'Sababu',
			'label.description': 'Maelezo',
			'description.input': 'Weka maelezo kuhusu suala',
			'submit.button': 'Wasilisha',
			'refer.someone': 'Elekeza Mtu',
			'label.referrals': 'Marejeleo',
			'label.first.name': 'Weka jina la kwanza la Mteja',
			'label.last.name': 'Weka jina la pili la Mteja',
			'label.phone': 'Ingiza nambari ya simu',
			'label.product.interest': 'Bidhaa ya Riba',
			'label.location': 'Eneo',
			'label.watersource': 'Chanzo cha maji',
			'label.purchasedate': 'Tarehe ya Ununuzi',
			'label.followupdate': 'Tarehe ya Kufuatilia',
			'referral.number': 'Nambari ya Rufaa',
			'list.details': 'Maelezo ya Orodha',
			'load.ticket': 'Inaleta tiketi. Tafadhali subiri...',
			'empty.ticket': 'Pole! Inaonekana hakuna tiketi zilizowasilishwa kwa sasa.',
			'load.referral': 'Inaleta rufaa. Tafadhali subiri...',
			'empty.referral':
				'Lo! Samahani Hakuna Orodha ya Marejeleo iliyopatikana.',
			'enter.valid.id':
				'Tafadhali weka Nambari Sahihi ya Kitambulisho.',
			'login.no.account':
				'Ombi halijafaulu. Hakuna maelezo ya Akaunti yaliyopatikana',
			'please.input.otp': 'Tafadhali Ingiza OTP ili kuendelea!',
			'valid.input.otp': 'Tafadhali weka msimbo halali wa OTP!',
			'login.success': 'Msimbo wa SMS umetumwa kwa kifaa chako.',
			'invalid.input.otp':
				'Lo! Msimbo batili wa OTP umeingizwa. Tafadhali jaribu tena',
			'session.expired': 'Muda wa kipindi chako umeisha. Tunakuondoa.',
			'something.wrong':
				'Ombi halijafaulu. Kitu Kimeharibika. Tafadhali jaribu tena.',
			'confirm.raise.ticket':
				'Ungependa kuthibitisha kuwa unataka kuongeza tikiti?',
			'confirm.ticket':
				'Tikiti yako imewasilishwa kwa mafanikio. Timu yetu itawasiliana ndani ya masaa 24 ili kufuatilia hili. Asante kwa kuwa mteja wa SunCulture',
			'label.loading': 'Subiri...',
			'confirm.logout': 'Je, una uhakika unataka kuondoka?',
			'find.id':
				'Tafadhali tafuta kitambulisho cha kifaa nyuma ya pakiti ya betri yako ambayo huanza na 86092204',
			'where.can.find.id': 'Ninaweza kupata wapi yangu',
			'device.id.question': 'Kitambulisho cha Kifaa?',
			'label.ok': 'Sawa',
			'forecast.title': 'UTABIRI WA SIKU 6',
			'btn.correct': 'Sahihi',
			'btn.not.correct': 'Si sahihi',
			'geolocation.not.supported':
				'Eneo la kijiografia halitumiki na kivinjari chako',
			'label.locating': 'Tunatafuta eneo lako. Tafadhali subiri...',
			'unable.retrieve':
				'Lo! Haikuweza kurejesha eneo lako. Tafadhali ruhusu kivinjari kufikia eneo lako',
			'current.location': 'Eneo lako la sasa: ',
			'label.river': 'Mto',
			'label.dam': 'Bwawa',
			'label.lake': 'Ziwa',
			'label.rain': 'Mvua',
			'label.shallow.water': 'Maji ya kina kirefu',
			'label.rain.water.harvest': 'Mvua Kuvuna maji',
			'pay.now':
				'LIPA https://github.com/SunCulture/Customer_portal_Innovation/pull/456sasa',
			'label.cancel': 'Ondoa',
			'mpesa.form.msisdn': 'Weka nambari yako ya simu(Anza na 254)',
			'mpesa.form.amount': 'Weka Kiasi cha kulipa',
			'mpesa.form.description': 'Maelezo',
			'mpesa.success.launch':
				'Ombi lako la kulipa limefaulu. Subiri na uweke PIN yako kwenye Kifaa chako',
			'mpesa.dialog.desc':
				'Weka nambari yako ya simu, kiasi na maelezo ili kukamilisha mchakato wa malipo.',
			'label.download': 'Pakua',
			'customer.enquiry': 'Uchunguzi wa Mteja',
			'payment.issue': 'Shida ya malipo',
			'technical.issue': 'Shida kwenye kifaa',
			'device.upgrade': 'Uboreshaji wa Kifaa',
			'device-nb': 'Ufafanuzi',
			'note.description':
				'Pata maelezo ya hoja utazoziwasilisha hapa chini , Tafadhali',
			'reason.header': 'Sababu',
			'more.header': 'Maelezo Zaidi',
			'enquiry.info':
				'Maswali/ Je unataka hakikisho kuhusu kifaa chako au huduma zetu ?',
			'complaint.info': 'Malamishi ya kutoridhika ?',
			'technical.info': 'Unashida/Changamoto kwenye kifaa tujulishe ?',
			'payment.more-info':
				'Wasilisha malamishi ya malipo mfano: Malipo kutokamilika vizuri ?',
			'device.info': 'Nia ya ongezeko kwa kifaa ulichonunua au uboreshaji ?',
			'characters-msg': 'Lazima iwe Alphabeti zaidi ya moja',
			'input-number-msg': 'Tafadhali weka Nambari ya Simu',
			'phone-input-digits': 'Tafadhali weka Nambari sahii ya Simu',
			'idnumber-input-digits': 'Tafadhali weka nambari sahihi ya utambulisho',
			'go-back-btn': 'Rudi Ulikotoka',
			'header.refer.earn': 'Rejea Mtu yeyote ili Upate KSH.1000 ',
			'train.link.title1': 'Boresha Mavuno',
			'train.link.title2': 'yako Leo',
			'explore.training': 'Angalia mafunzo yetu',
			'header.take.training': 'Chukua mafunzo',
			'device.info.today': 'Leo',
			'device.info.weekly': 'Kila wiki',
			'device.info.watthours': 'Saa za Watt zinazozalishwa',
			'device.info.wattconsumed': 'Saa za Watt zinazotumiwa',
			'device.info.wattsgenerating': 'Watts kuzalisha',
			'device.info.moneysaved': 'Pesa zimehifadhiwa:',
			'device.info.lastconnected': 'Mara ya Mwisho Kuunganishwa:',
			'device.info.performance': 'Utendaji wa kifaa',
			'mydevice.verify.help.title': 'Nyuma ya betri inayoanza nayo',
			'mydevice.verify.help.id': '86',
			'label.Watt': 'Wati',
			'label.Watthours': 'Saa za Watt',
			'message.session.expired':
				'Ombi halijafaulu. Muda wa Kipindi chako umeisha. Tafadhali ondoka na uingie tena',
			'message.unable.verify.device':
				'Ombi halijafaulu. Hatuwezi kuthibitisha kifaa chako kwa wakati huu',
			'message.no.device.info':
				'Samahani hakuna maelezo ya kifaa yanayopatikana',
			'login.no.account.button': 'Je, huna akaunti?',
			'login.join.suncculture': 'Jiunge na Utamaduni wa Jua',
			'weather.for.week': 'Hali ya hewa kwa wiki',
			'weather.humidity': 'Unyevu',
			'login.account.already': 'Je, tayari una akaunti?',
			'login.here': 'Ingia hapa',
			'label.optional': 'Hiari',
			'label.select.watersource': 'Chagua Chanzo cha Maji',
			'label.select.country': 'Chagua Nchi',
			'label.select.product': 'Chagua Bidhaa ya Kuvutia',
			'signup.error.msg.firstname': 'Tafadhali Weka Jina la Kwanza',
			'signup.error.msg.firstname.invalid': 'Jina la Kwanza linapaswa kuwa na herufi zaidi ya 1',
			'signup.error.msg.lastname': 'Tafadhali Andika Jina la Mwisho',
			'signup.error.msg.lastname.invalid': 'Jina la mwisho linapaswa kuwa na herufi zaidi ya 1',
			'signup.error.msg.phonenumber':
				'Tafadhali Weka Nambari Sahihi ya Simu anza na 254 or 256',
			'signup.error.msg.refferal.phonenumber':
				'Please Enter Valid Refferal Phone Number start with 0 or 254 or 256',
			'signup.error.msg.location': 'Tafadhali Weka Mahali',
			'signup.error.msg.referral.name': 'Tafadhali Weka Jina la Rufaa',
			'yield.improve.title': 'Boresha mavuno yako leo',
			'yield.improve.subtitle': 'Chunguza mafunzo yetu',
			'btn.label.explore': 'Chunguza',
			'mydevice.not.verified': 'Kifaa chako hakijathibitishwa',
			'btn.title.see.more': 'Ona zaidi',
			'label.complaint': 'Malalamiko',
			'mydevice.verify.help.note2':
				'tafadhali Weka Kitambulisho cha Kifaa chenye tarakimu 15 ili kuthibitisha kifaa chako',
			'ticket.tab.data.all': 'Wote',
			'ticket.tab.data.pending': 'Inasubiri',
			'ticket.tab.data.done': 'Imekamilika',
			'message.unable.fetch.device.information':
				'Ombi halijafaulu. Hatuwezi kuleta maelezo ya kifaa chako kwa wakati huu',
			'msg.no.device.id.attatched':
				'Hakuna kifaa mbacho kimekabidhiwa kwa akaunti hii. Tafadhali tupigie simu kupitia nambari yetu ya simu isiyolipishwa au utoe wasiwasi kuhusu changamoto unazoweza kukabiliana nazo wakati wa kuthibitisha kifaa chako.',
			'account.installement': 'Taarifa ya awamu',
			'account.how.to.pay': 'Jinsi ya Kulipa',

			'payment.reminder.needtopay': 'Unahitaji kulipa',
			'payment.reminder.pastdue': 'Iliyopita',
			'btn.title.pay': 'Lipa',
			'productive.top.users': 'Watumiaji Maarufu SunCulture',
			'productive.5points.title': 'kwa Ingia kwa Tovuti ya Wateja',
			'productive.50points.title': 'kwa rufaa mpya',
			'label.productivity.assesment': 'Tathmini Yangu ya Uzalishaji',
			'label.past.due': 'Iliyopita',
			'device.no.telemetry.data': 'huduma haipatikani, jaribu tena baadaye',
			'accounts.payments.done': 'Malipo ya akaunti yamesasishwa',
			'label.account': 'Akaunti',
			'btn.label.refer': 'Rejea',
			'invite.reffer.frined': 'Alika rafiki na upate pointi',
			'label.banner.point.text': 'pointi',
			'label.no.payment.left': 'Malipo ya akaunti yamesasishwa',
			'label.no.due.arrears': 'HAKUNA MADILIKO',
			'label.total.amount': 'Kiasi kinachowezekana',
			'refer.card.id': 'Kitambulisho cha rufaa',
			'refer.card.name': 'Jina',
			'refer.card.phone': 'Nambari ya simu',
			'refer.card.location': 'Mahali',
			'refer.card.product.interested': 'Bidhaa Inavutiwa',
			'refer.card.earned.money': 'Pesa iliyopatikana',
			'label.no.due.installment': 'HAKUNA USIMAMIZI WA KUSTAHILI',
			'label.do.refer': 'Mpelekee Rafiki yako',
			'label.installment.note': 'Hesabu ya Kiasi cha Malipo',
			'label.info.for.installment':
				'Kiasi kinachofuata cha malipo huhesabiwa kwa kuongeza malipo ya awali (ikiwa yapo) na malipo yako ya kawaida ya awamu ya KES.',
			'label.info.part.2.for.installment':
				'Thamani hii inaweza kuwa kidogo unapolipa mapema.',
			'label.create.refer.note':
				'Pata maelezo zaidi kuhusu bidhaa za SunCulture',
			'soil.test.title': 'Matokeo ya jaribio lako la udongo kufanyika',
			'btn.title.see': 'Tazama',
			'not.found.page.title': 'Lo! Samahani Ukurasa Haujapatikana.',
			'select.device.title': 'Chagua Akaunti yako',
			'select.device.desc': 'Tafadhali chagua akaunti ili kuendelea',
			'label.retry': 'Jaribu tena',
			'title.select.device': 'Chagua Kifaa',
			'label.select.device': 'Badili Kifaa',
			'label.confirm.reload':
				'Je, una uhakika unataka kuondoka? Data yote iliyotolewa itapotea.',
			'label.select.account': 'Chagua Akaunti',
			'title.register.device': 'Sajili Kifaa chako',
			'title.btn.register.device': 'Sajili Kifaa',
			'title.btn.view.register.device': 'Tazama Maelezo',
			'title.btn.self.register.device.details':
				'Maelezo ya Kifaa yaliyosajiliwa yenyewe',
			'device.register.form.deviceId': 'Kitambulisho cha Kifaa',
			'device.register.form.paygContractNumber': 'Nambari ya Mkataba',
			'device.register.form.passportImage': 'Picha ya Ukubwa wa Pasipoti',
			'device.register.form.frontIdImage': 'Picha ya kitambulisho (Mbele)',
			'device.register.form.backIdImage': 'Picha ya kitambulisho (Nyuma)',
			'device.register.form.farmImage': 'Picha ya Shamba',
			'device.register.form.pumpImage': 'Picha ya Bomba',
			'device.register.form.waterSourceImage': 'Picha ya Chanzo cha Maji',
			'device.register.form.deviceIdImage': 'Picha ya Kitambulisho cha Kifaa',
			'device.register.form.signedContractImage': 'Picha ya Mkataba',
			'device.register.form.warrantCardImage': 'Picha ya Kadi ya Udhamini',
			'label.select.image': 'Pakia Picha',
			'device.register.form.farmmanagement': 'Usimamizi wa shamba',
			'device.register.form.farmOwnership': 'Umiliki wa shamba',
			'device.register.form.state': 'Kata kata',
			'device.register.form.substate': 'Chagua Wilaya ndogo',
			'device.register.form.town': 'Mji',
			'device.register.form.hasWater': 'Upatikanaji wa Maji',
			'device.register.form.hasElectricityOnFarm': 'Umeme kwenye Shamba',
			'device.register.form.waterSourceDepth': 'Kina cha Maji',
			'device.register.form.waterSourceDistance': 'Umbali wa Maji',
			'device.register.form.whenWasWellDug': 'Vizuri Chimba',
			'device.register.form.monthlyPumpingCost':
				'Gharama ya Pampu ya Kila Mwezi',
			'device.register.form.currentIrrigationMethod': 'Mbinu ya Umwagiliaji',
			'device.register.form.totalFarmSizeAcres': 'Ukubwa wa Shamba (Ekari)',
			'device.register.form.waterTankCapacityInLitres':
				'Uwezo wa Tangi la Maji (ltrs)',
			'device.register.form.cropsToBeGrown': 'Mazao Yaliyopandwa',
			'device.register.form.didSoilTest': 'Mtihani wa Udongo',
			'device.register.form.property.images': 'Picha za Mali',
			'device.register.form.waterSource.images': 'Picha za Chanzo cha Maji',
			'device.register.form.farmName': 'Jina la shamba',
			'label.yes': 'Ndiyo',
			'label.no': 'Hapana',
			'label.remove': 'Ondoa',
			'label.add.images.property': '+ Picha za Mali',
			'label.add.images.watersource': '+ Picha za Chanzo cha Maji',
			'label.no.device': 'Hakuna Kifaa Kinachopatikana',
			'title.self.register.banner': 'Jisajili Kifaa chako',
			'label.self.register.banner':
				'Kuwa na kifaa cha kujiandikisha! Bonyeza tu kwenye Sajili na usajili kifaa chako kwa hatua rahisi',
			'label.back': 'Nyuma',
			'label.next': 'Hifadhi',
			'label.err.message.stepper.form':
				'*Hakikisha umeingiza maelezo yote yanayohitajika kwa usahihi',
			'label.success.form.submitted':
				'Umewasilisha kwa ufanisi maelezo yote yanayohitajika kwa ajili ya kusajili kifaa',
			'label.step': 'Hatua',
			'label.purchase.date': 'Tarehe ya Kununua',
			'label.now': 'Sasa',
			'label.two.weeks': 'Wiki mbili',
			'label.two.months': 'Miezi miwili',
			'label.later': 'Baadae',
			'label.submitted.suceess.title':
				'Umewasilisha kwa ufanisi maelezo yote yanayohitajika kwa ajili ya kusajili kifaa.',
			'label.submitted.suceess.sub.title':
				'Tafadhali subiri Timu ya Sunculture iidhinishe kifaa chako.',
			'label.submitted.suceess.note': 'Hii inaweza kuchukua saa chache.',
			'label.registration.suceess.title': 'Kifaa chako kimesajiliwa.',
			'label.registration.suceess.sub.title':
				'Unaweza kutazama maelezo yaliyowasilishwa kwa kutumia vitufe vilivyo hapa chini',
			'label.farm.details.note':
				'Tafadhali hakikisha kuwa uko kwenye shamba lako wakati unasasisha eneo lako.',
			'label.btn.add.location': 'Ongeza Mahali',
			'label.btn.take.pictures': 'Kuchukua picha',
			'title.add.permise.location': 'Ongeza Eneo la Shamba',
			'title.save.image': 'Hifadhi Picha',
			'title.camera': 'KAMERA',
		},
	},
};
export default resources;
