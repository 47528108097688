const Colors = {
	Brand: 'linear-gradient(135deg, #7CE27E 0%, #07B382 100%)',
	Linear: 'linear-gradient(160.98deg, #F1D500 6.6%, #02C7F1 115.02%)',
	Creen: '#5FD36D',
	Green: '#55C963',
	Black: '#141414',
	White: '#FFFFFF',
	Blue_dark: '#252543',
	Light: '#F7F8FA',
	orange: '#F1A63F',
	blue: '#3897E0',
	pank: '#F23F3F',
	yellow: '#F5D00B',
	light_green: '#D6FFD6',
	Gray: '#636569',
	danger_notif:
		'linear-gradient(0deg, rgba(242, 63, 63, 0.08), rgba(242, 63, 63, 0.05)), #FFFFFF',
	error_notif: 'rgba(235, 0, 0 ,0.17)',

	success_notif:
		'linear-gradient(0deg, rgba(95, 211, 109, 0.08), rgba(95, 211, 109, 0.08)), #FFFFFF',
	blue_notif:
		'linear-gradient(0deg, rgba(56, 151, 224, 0.08), rgba(56, 151, 224, 0.08)), #FFFFFF',
	warning_notif:
		'linear-gradient(0deg, rgba(241, 166, 63, 0.08), rgba(241, 166, 63, 0.08)), #FFFFFF',
	banner_bg: '#141414',
	light_Yellow: 'rgba(241, 166, 63, 0.12)',
};

export default Colors;
